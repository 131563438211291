import React from 'react';
import { Quill } from 'react-quill';
import { toast } from 'react-toastify';
import successicon from '../assets/img/success_toast.svg';
import failedicon from '../assets/img/failed_toast.svg';

// React Quill
const Font = Quill.import('attributors/style/font');
Font.whitelist = ['times-new-roman', 'arial', 'initial', 'sans-serif', 'serif', 'monospace'];
Quill.register(Font, true);

export const modules = () => ({
    toolbar: {
        container: [
            [{ font: ['sans-serif', 'serif', 'monospace'] }],
            // [{ size: ['small', 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, false] }],
            ['bold', 'italic', 'underline'], // toggled buttons
            [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['clean'], // remove formatting button
        ],
    },
});

export const getQueryParams = (data) => {
    let queryString = '';
    const params = Object.keys(data);
    params.map((param) => {
        if (data[param]) queryString += `&${param}=${data[param]}`;
        return queryString;
    });
    return queryString;
};

export const formats = () => [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'mention',
    'background',
    'script',
    'align',
    'color',
];

// Select
export const defaultOptions = [
    { value: '1cace913-54f3-43ec-a978-c8a523b6c6da', label: 'option 1' },
    { value: '2', label: 'option 2' },
    { value: '', label: 'option 3' },
];

// Notification
const toastConfig = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    pauseOnFocusLoss: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
};
// info, success, warning, error, default
export const Notifications = (message = null, type = 'default') => {
    if (!message) return;
    toast(message, {
        ...toastConfig,
        type,
        toastId: message,
        // eslint-disable-next-line no-unused-vars, no-shadow
        icon: ({ theme, type }) => {
            if (type === 'success') {
                return <img src={successicon} alt="img" />;
            }
            if (type === 'error') {
                return <img src={failedicon} alt="img" />;
            }
        },
    });
};

// Email Validation
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in|uk|net|org|info)$/;

export const validateEmail = (val) => emailRegex.test(String(val).toLowerCase());

// Validate Text Area

export const validateTextArea = (val) => {
    if (!val || val?.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
        return false;
    }
    return true;
};

export const validateTextLength = (val) => {
    if (val && val?.replace(/<(.|\n)*?>/g, '').trim().length < 25) {
        return false;
    }
    return true;
};

// Default Errors
export const errorNotify = [
    'VALIDATION_ERROR',
    'INCORRECT_PASSWORD',
    'INVALID_EMAIL',
    'EMAIL_NOT_FOUND',
    `\"id\" must be a valid GUID`,
];

// Valid Password

const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,15}$/;

// const passRegex = /.{8,}/;

export const validatePass = (val) => passRegex.test(val);

export const queryResponseFormat = (response) =>
    response?.data?.success ? response?.data : { message: response?.data?.message || 'Something went wrong' };

// Phone number Validation
// const phonenoRegex = /^\+\d{2}\d{5}\d{5}$/;
const phonenoRegex = /^(\+|\d)[0-9]{8,15}$/;

export const validatePhoneNumber = (val) => phonenoRegex.test(val);

// check wheather number or not
const isNumberRegex = /^\d+$/;
export const validateNumber = (val) => isNumberRegex.test(val);
// EmployeeId Validation
const empIdRegex = /^[a-zA-Z0-9]+$/;
export const validateEmpId = (val) => empIdRegex.test(val);

// FirstName Validation
const firstNameRegex = /^[a-zA-Z ]+$/;
export const validateFirstName = (val) => firstNameRegex.test(val);

// LastName Validation
export const validateLastName = (val) => firstNameRegex.test(val);

// Employee Email Validation
// const empEmailValidation = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
const empEmailValidation = /^[a-zA-Z0-9._%+-]+@spericorn\.com$/;
export const validateEmpEmail = (val) => empEmailValidation.test(val.trim());
const clientEmailValidation = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
export const validateClientEmail = (val) => clientEmailValidation.test(val.trim());

// Domain Validation
const urlRegex =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
export const validateUrl = (val) => urlRegex.test(val?.trim());
const domainRegex = /^(?!.* .*)(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
export const validateDomain = (val) => domainRegex.test(val.trim());

// TextField Validation
const textFieldRegex = /^[a-zA-Z0-9\s\-,'&.()!@#\$%\^\*_\+=\{\}\[\]\\|:;"<>\?\/]{1,255}$/;
export const validateTextField = (val) => textFieldRegex.test(val);

// URL Validation
const URLRegex = /^((https?|ftp):\/\/)?([a-z0-9\-]+\.)+[a-z]{2,63}(\/[a-zA-Z0-9\-._~:\/\?#\[\]@!$&'()*+,;=]*)?$/;
export const validateURL = (val) => URLRegex.test(val.trim());

// Website Valid
const WebRegex =
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/;
export const validateWeb = (val) => WebRegex.test(val.trim());

// Client Name Validation
const ClientNameRegex = /^[A-Za-z ]{2,30}$/;
export const validateClientName = (val) => ClientNameRegex.test(val.trim());

// Valid IP address
const IpRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const validateIP = (val) => IpRegex.test(val.trim());

export const cleanContent = (content) => {
    const conetntChange = content?.replaceAll('style="color: rgb(255, 255, 255);"');
    if (conetntChange === '<p><br></p>') return '';
    return conetntChange;
};

export const cleanText = (content) => {
    if (content) {
        return content.replace(/<\/?[^>]+(>|$)/g, '');
    }
    return '';
};

export const truncateText = (text, len) => {
    if (!text || !len) return '';
    return text.length > len ? `${text.slice(0, len - 1)}…` : text;
};
