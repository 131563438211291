import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import sidebarOptions from './sidebarOptions';
import hamIcon from '../../assets/img/ham-menu.svg';
// import altLogo from '../../assets/img/tender_white.svg';
import mobileLogo from '../../assets/img/logo-small.svg';
// import mobileLogo from '../../assets/img/logo_small.svg';
import spericornLogo from '../../assets/img/spericorn-proposal-logo.svg';
import appstoreLogo from '../../assets/img/app_store.svg';
import playstoreLogo from '../../assets/img/google-store.svg';
import applesmallLogo from '../../assets/img/app-store-sm.svg';
import androidsmallLogo from '../../assets/img/play-store-sm.svg';

import dashboardIcon from '../../assets/img/icon-menu-dashboard.svg';
import employeeManagementIcon from '../../assets/img/icon-menu-group.svg';
import portfolioIcon from '../../assets/img/page_portfolio.svg';
import permissionManagementIcon from '../../assets/img/icon-menu-permission.svg';
import proposalManagementIcon from '../../assets/img/icon-menu-proposals-management.svg';
import conversationsIcon from '../../assets/img/icon-menu-conversions.svg';
import documentsIcon from '../../assets/img/icon-menu-documents.svg';
import suggessionsIcon from '../../assets/img/Suggestion.svg';
import templateIcon from '../../assets/img/template.svg';
import portfoliosIcon from '../../assets/img/portfolio.svg';
import clientsIcon from '../../assets/img/client.svg';
import technologiesIcon from '../../assets/img/technology.svg';
import { getDefaultRoute } from '../../components/permissionManagement/util';

const navIcons = {
    PORTFOLIO: portfoliosIcon,
    PERMISSION: permissionManagementIcon,
    DASHBOARD: dashboardIcon,
    PROPOSAL: proposalManagementIcon,
    USERS: employeeManagementIcon,
    CONVERSIONS: conversationsIcon,
    DOCUMENTS: documentsIcon,
    SUGGESTION: suggessionsIcon,
    SUGGESTIONS: suggessionsIcon,
    TEMPLATES: templateIcon,
    TECHNOLOGIES: technologiesIcon,
    CLIENT: clientsIcon,
};
function Sidenav({ collapse, setCollapse }) {
    // const width = window.innerWidth;
    const location = useLocation();

    const [subDrop, setSubDrop] = useState('');
    const [innerDrop, setInnerDrop] = useState(false);

    const { userPermissions } = useSelector((state) => state.permissionReducer);

    useEffect(() => {
        sessionStorage.setItem('sidebarCollapsed', collapse);
    }, [collapse]);

    useEffect(() => {
        if (collapse) {
            setSubDrop('');
            setInnerDrop('');
        } else {
            setSubDrop(location?.pathname?.split('/')[1] || '');
            setInnerDrop(location?.pathname?.split('/')[2] || '');
        }
    }, [collapse]);

    useEffect(() => {
        if (subDrop && !location?.pathname?.includes(subDrop)) {
            setSubDrop('');
        }
    }, [location]);

    useEffect(() => {
        const { innerWidth: width } = window;
        if (width < 991) setCollapse(false);
    }, [location.pathname]);

    const getInnerMenu = (permissions = [], subPath) => {
        const withSub = permissions?.filter(({ sub_permissions }) => sub_permissions?.length);
        if (!withSub?.length) {
            return null;
        }
        return withSub.map(({ permission, permission_label = '' }) => ({
            innerlabel: permission_label,
            permission,
            innerpath: `${subPath}/${permission_label.toLowerCase().replace(' ', '-')}`,
        }));
    };

    const getRoutesWithPermission = () => {
        const s = userPermissions.map(({ module_label, module, subModules }) => ({
            label: module_label,
            path: `/${module_label.toLowerCase().replace(' ', '-').replace('_', '-')}`,
            navIcon: navIcons[module],
            permission: module,
            subMenus: subModules
                .filter(({ webVisibility }) => webVisibility === true)
                .map(({ module_label: sublabel, module: subModule, permissions }) => ({
                    sublabel,
                    subpath: `/${module_label.toLowerCase()}/${subModule
                        .toLowerCase()
                        .replace(' ', '-')
                        .replace('_', '-')}`,
                    permission: subModule,
                    innerMenu: getInnerMenu(permissions, `/${module_label.toLowerCase()}/${subModule.toLowerCase()}`),
                })),
        }));
        return [...s];
    };

    const comparePath = (path) => {
        const check1 = path.split('/').filter((item) => item);
        const check2 = location.pathname.split('/').filter((item) => item);
        if (!check1?.length || !check2?.length) return false;
        return check2?.includes(check1?.pop());
    };

    return (
        <div className={`sidebar ${collapse && 'sidebar--Collapse'}`}>
            <div className="otr">
                <div className="logo-toggle">
                    <div className="logo-brand">
                        <Link to={getDefaultRoute(userPermissions)}>
                            {/* <img src={spericornLogo} alt="" /> */}
                            <img src={spericornLogo} alt="" />
                        </Link>
                    </div>
                    <div
                        className="collapsetoggle"
                        onClick={() => {
                            setCollapse(!collapse);
                        }}
                    >
                        <span id="toggleIcon" className="toggle-btn">
                            <img src={hamIcon} alt="" />
                        </span>
                    </div>
                    <div className="logo-brand-sm">
                        <Link to={getDefaultRoute(userPermissions)}>
                            <img src={mobileLogo} alt="" />
                        </Link>
                    </div>
                </div>

                <div className="side-inner-cover">
                    <ul className="nav nav-sidebar">
                        {getRoutesWithPermission()?.map(({ path, label, navIcon, subMenus }) => {
                            if (subMenus?.length) {
                                return (
                                    <li
                                        className={`click-to-expand  ${subDrop === path ? 'show' : ''} ${
                                            location?.pathname?.includes(path) ? 'active' : ''
                                        }`}
                                        key={path}
                                    >
                                        <a
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setSubDrop(subDrop === path ? '' : path);
                                                if (!subDrop && collapse) {
                                                    setCollapse(true);
                                                }
                                            }}
                                        >
                                            <span className="menu-icon">
                                                <img src={navIcon} alt="" />
                                            </span>
                                            <span className="menutext">{label}</span>
                                        </a>

                                        <ul className="submenu-expand">
                                            {subMenus?.map(({ sublabel, subpath, innerMenu }) => {
                                                if (innerMenu?.length && subDrop) {
                                                    return (
                                                        <li
                                                            className={`inner-nav-expand ${
                                                                subpath === innerDrop && 'show'
                                                            }`}
                                                            key={subpath}
                                                        >
                                                            <a
                                                                className="subnav-link pdg cursor-pointer"
                                                                onClick={() =>
                                                                    setInnerDrop(subpath === innerDrop ? '' : subpath)
                                                                }
                                                            >
                                                                Settings
                                                            </a>
                                                            <ul className="submenu-inner">
                                                                {innerMenu?.map(({ innerlabel, innerpath }) => (
                                                                    <li key={innerpath}>
                                                                        {/* <Link > */}
                                                                        <Link
                                                                            to={innerpath}
                                                                            className={`subnav-link-inner ${
                                                                                location?.pathname === innerpath &&
                                                                                'active'
                                                                            }`}
                                                                        >
                                                                            {innerlabel}
                                                                        </Link>
                                                                        {/* </Link> */}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </li>
                                                    );
                                                }
                                                return (
                                                    <li>
                                                        {/* <Link> */}
                                                        <Link
                                                            to={subpath}
                                                            className={`subnav-link pdg ${
                                                                (location?.pathname === subpath ||
                                                                    comparePath(subpath)) &&
                                                                'active'
                                                            }`}
                                                        >
                                                            {sublabel}
                                                        </Link>
                                                        {/* </Link> */}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                );
                            }
                            return (
                                <li className={location?.pathname === path ? 'active' : ''} key={path}>
                                    <Link to={path}>
                                        <span className="menu-icon">
                                            <img src={navIcon} alt="" />
                                        </span>
                                        <span className="menutext">{label}</span>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>

                    <div className="side-ftr">
                        <div className="app-store-small">
                            <a
                                href="https://apps.apple.com/us/app/spericorn-proposal/id6471644735?platform=iphone"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="store-icon"
                            >
                                <img src={applesmallLogo} alt="" />
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.spericorn.proposal&pli=1"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="store-icon"
                            >
                                <img src={androidsmallLogo} alt="" />
                            </a>
                        </div>
                        <div className="app-store">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/us/app/spericorn-proposal/id6471644735?platform=iphone"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={appstoreLogo} alt="" />
                                    </a>
                                </li>

                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.spericorn.proposal&pli=1"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={playstoreLogo} alt="" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidenav;
